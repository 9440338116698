import { ApolloManager } from 'src/helpers/ApolloManager';
import getDelivery from './getDelivery.graphql';
import createDelivery from './createDelivery.graphql';
import getDeliveriesForUser from './getDeliveriesForUser.graphql';

export interface Delivery {
	id?: string;
	deliveryDate: Date;
	email: string;
	order: string;
}

interface CreateDeliveryResult {
	createDelivery: Delivery;
}

interface DeliveryFetchResult {
	allDeliveries: Delivery[];
}

class DeliveryImplementation {
	public readonly getDelivery = async (id: string): Promise<Delivery | undefined> => {
		try {
			const result = await ApolloManager.client.query<DeliveryFetchResult>({
				query: getDelivery,
				variables: { id },
				fetchPolicy: 'no-cache',
			});
			if (result.data.allDeliveries && result.data.allDeliveries.length > 0) {
				return result.data.allDeliveries[0];
			}
		} catch (error) {
			console.error(`An error occurred fetching delivery: Error was ${error}`);
		}
		return undefined;
	};

	public readonly createDelivery = async (delivery: Delivery): Promise<Delivery | undefined> => {
		try {
			const result = await ApolloManager.client.mutate<CreateDeliveryResult>({
				mutation: createDelivery,
				variables: delivery,
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.createDelivery) {
				return result.data.createDelivery;
			}
		} catch (error) {
			console.error(`An error occurred creating delivery: Error was ${error}`);
		}
		return undefined;
	};

	public readonly getDeliveriesForUser = async (
		userId?: number
	): Promise<Delivery[] | undefined> => {
		if (!!!userId) return undefined;
		try {
			const result = await ApolloManager.client.query<{ allDeliveries: Delivery[] }>({
				query: getDeliveriesForUser,
				variables: { user: userId },
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.allDeliveries && result.data.allDeliveries.length > 0) {
				return result.data.allDeliveries;
			}
		} catch (error) {
			console.error(`Delivery data retrieval failed. ${error}`);
		}
		return undefined;
	};
}

export const DeliveryManager = new DeliveryImplementation();
